import React from "react";
import "./Footer.scss";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

import PaylaterLogo from "@assets/img/paylater-logo.svg";
import UzFlag from "@assets/img/uzFlag.png";
import RussianFlag from "@assets/img/russianFlag.png";
import inst from "@assets/img/inst.svg";
import telegram from "@assets/img/telegram.svg";
import { socialMediaLinks } from "@consts/socialMediaLinks.js";

import { useGlobalState } from "@/GlobalStateContext.js";

import publicOfferDoc from "@assets/documents/Публичная_оферта.pdf";
import specialOffersDoc from "@assets/documents/10_09_2024_МФО_PAYLATER_Маркетинговая_акция v2.pdf";
import infoMFODoc from "@assets/documents/МФО.jpg";

function Footer() {
  const { language, setLanguage, siteVersion, setSiteVersion } =
    useGlobalState();
  const { t: tCommon } = useTranslation(["common"]);
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);

  return (
    <div className="footer">
      <div className="footer-container">

        <div className="footer-head-block">
          <img src={PaylaterLogo} alt="paylater-logo" />
          {language === "ru" ? (
            <button className="mob-btn" onClick={() => setLanguage("uz")}>
              <img
                src={UzFlag}
                alt="uz-flag"
                className="lang-icon"
                draggable="false"
              />
              UZ
            </button>
          ) : (
            <button className="mob-btn" onClick={() => setLanguage("ru")}>
              <img
                src={RussianFlag}
                alt="uz-flag"
                className="lang-icon"
                draggable="false"
              />
              RU
            </button>
          )}
        </div>

        <div className="footer-info-block">
          <div className="nav-links">
            <div className="nav-label">Paylater</div>
            <div className="footer-anchor anchor-link">
              <Link to="benefits" smooth>{tCommon("anchors.benefits")}</Link>
            </div>
            <div className="footer-anchor anchor-link">
              <Link to="partner-shops" smooth>{tCommon("anchors.partnerShops")}</Link>
            </div>
            <div className="footer-anchor anchor-link">
              <Link to="banner" smooth>{tCommon("anchors.banner")}</Link>
            </div>
            <div className="footer-anchor anchor-link">
              <Link to="howItWork" smooth>FAQ</Link>
            </div>
            {siteVersion === "client" ? (
              <div className="footer-anchor anchor-link" onClick={() => setSiteVersion("business")}>
                {tSiteVersion("button.text")}
              </div>
            ) : (
              <div className="footer-anchor anchor-link"onClick={() => setSiteVersion("client")}>
                {tSiteVersion("button.text")}
              </div>
            )}
          </div>

          <div className="nav-links">
            <div className="nav-label">{tCommon("address.title")}</div>
            <div className="footer-anchor" dangerouslySetInnerHTML={{ __html: tCommon("address.text") }} />
            
            <div className="nav-label anchor-link">
              <a 
                href={publicOfferDoc} 
                target="_blank" 
                rel="noreferrer"
              >
                {tCommon("publicOffer.title")}
              </a>
            </div>
            <div className="nav-label anchor-link mt-0">
              <a 
                href={specialOffersDoc} 
                target="_blank" 
                rel="noreferrer"
              >
                {tCommon("specialOffers.title")}
              </a>
            </div>
            <div className="nav-label anchor-link mt-0">
              <a 
                href={infoMFODoc} 
                target="_blank" 
                rel="noreferrer"
              >
                {tCommon("infoMFO.title")}
              </a>
            </div>
          </div>
          <div className="nav-links">
            <div className="nav-label">{tCommon("requisites.title")}</div>
            <div className="footer-anchor" dangerouslySetInnerHTML={{ __html: tCommon("requisites.details") }} />
          </div>

          <div className="nav-links">
            <div className="nav-label">{tCommon("callCenter.title")}</div>
            <div className="footer-anchor">+998 78 113 70 77</div>
            <div className="footer-anchor">+998 78 113 84 88</div>
            <div className="social-links">
              <div className="nav-label">{tCommon("socials.title")}</div>
              <div className="d-flex-end-center">
                <a href={socialMediaLinks.instagram}>
                  <img alt="inst" src={inst} />
                </a>
                <a href={socialMediaLinks.telegram}>
                  <img alt="telegram" src={telegram} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
